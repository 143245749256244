import { type FC,useState } from "react";
import { Box } from "@mui/material";
import { AnswersBlock, StyledSecondaryHeader  } from "./components";
import type { FoundAnswersProps } from "./interfaces";

const FoundAnswers:FC<FoundAnswersProps> = ({ annotation, onEdit }) => {
  const [isEditing, setIsEditing] = useState({ labelId: null, index: 0 })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: '10px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px'
        }}
      >
        <StyledSecondaryHeader variant="body1" bgcolor={annotation.color}>
          Found answers:
        </StyledSecondaryHeader>
      </Box>
      <AnswersBlock
        isEditing={isEditing}
        annotation={annotation}
        onEdit={onEdit}
        setIsEditing={setIsEditing}
      />
    </Box>
  )
}

export default FoundAnswers;