import { type FC, useState, useRef } from 'react';
import { Box, Button, IconButton, Tooltip, Typography, Checkbox, Collapse, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledInput } from '../../components';
import { StyledAnswerText, StyledAnswerBox, StyledSourceBadge, StyledHeader, StyledText } from './components';
import type { Label } from '../../types';
import type { AnswersBlockProps } from "./interfaces";

const AnswersBlock: FC<AnswersBlockProps> = ({ annotation, onEdit, setIsEditing, isEditing}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const inputRef = useRef<{ getValue: () => string | undefined }>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const answerIsEditing = isEditing.labelId === annotation.labelId && currentIndex === isEditing.index;
  const { answers } = annotation;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % answers.length);

    if (isEditing.labelId) {
      setIsEditing({ labelId: null, index: null });
    }
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + answers.length) % answers.length);

    if (isEditing.labelId) {
      setIsEditing({ labelId: null, index: null });
    }
  };

  const onSaveAnnotation = (label: Label) => {
    if (inputRef.current) {
      const newAnswers = [...answers];
      const inputValue = inputRef.current.getValue();

      newAnswers[currentIndex] = {
        ...answers[currentIndex],
        answer: { ...answers[currentIndex].answer, Value: inputValue }
      };

      onEdit({ ...label, answers: [...newAnswers] });
    }

    setIsEditing({ labelId: null, index: null });
  };

  const onSaveAnswer = (label: Label) => {
    const newAnswers = [...answers];
    const newAnswer = { ...answers[currentIndex], selected: !answers[currentIndex].selected}
    newAnswers[currentIndex] = newAnswer;
    onEdit({ ...label, answers: [...newAnswers] });
  };

  let formattedDate = ''

  if (annotation?.answers[currentIndex]?.answer?.LastModified) {
    const date = new Date(annotation.answers[currentIndex].answer.LastModified);

    formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }

  return (
    <StyledAnswerBox>
      {answers.length === 0
        ? (
          <Typography variant="body1" sx={{ mb: 2 }}>No answers found for this question</Typography>
        ) : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onChange={() => onSaveAnswer(annotation)} checked={answers[currentIndex].selected} />
                <Typography>Save for final document</Typography>
              </Box>
              <Tooltip title="Edit">
                <IconButton aria-label="edit" onClick={() => setIsEditing({ labelId: annotation.labelId, index: currentIndex })} sx={{ position: 'relative', top: '-5px' }}>
                  <FontAwesomeIcon icon={faPenToSquare} size="xs" color={'rgb(30, 48, 80)'} />
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={{ display: answerIsEditing ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
              <UncontrolledInput ref={inputRef} initialValue={answers[currentIndex].answer.Value} />
              <Button
                variant="contained"
                onClick={() => onSaveAnnotation(annotation)}
                sx={{ p: 0, position: 'relative', top: '-2px', maxWidth: '100px', marginTop: '10px' }}
              >
                Save
              </Button>
            </Box>
            <StyledAnswerText variant="body1" bgcolor={annotation.color} sx={{ display: answerIsEditing ? 'none' : 'initial' }}>
              {answers[currentIndex].answer.Value}
            </StyledAnswerText>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: '100%',
                padding: '5px 0',
                position: 'relative',
                marginTop: '50px'
              }}>
              <StyledSourceBadge>{`Source: ${answers[currentIndex].badge}`}</StyledSourceBadge>
              <StyledHeader variant="body1" bgcolor={annotation.color}>
                Answer info:
              </StyledHeader>
              <IconButton onClick={toggleCollapse}>
                <FontAwesomeIcon size="xs" icon={isCollapsed ? faChevronDown : faChevronUp} />
              </IconButton>
            </Box>
            <Collapse in={!isCollapsed}>
              <StyledText variant="body1" bgcolor={annotation.color} >
                {`Last modified: ${formattedDate}`}
              </StyledText>
              {annotation.answers[currentIndex].answer.Link ?
                <StyledText variant="body1" bgcolor={annotation.color} sx={{ wordBreak: 'break-all' }} >
                  <Link href={annotation.answers[currentIndex].answer.Link} target="_blank" rel="noopener noreferrer">{annotation.answers[currentIndex].answer.Link}</Link>
                </StyledText>
              : ''}
            </Collapse>
          </>
        )
      }
      {answers.length > 1
        ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Button variant="contained" onClick={handlePrevious} disabled={answers.length <= 1}>
              Previous
            </Button>
            <Typography>{currentIndex + 1}/{answers.length}</Typography>
            <Button variant="contained" onClick={handleNext} disabled={answers.length <= 1}>
              Next
            </Button>
          </Box>
        ) : ''
      }
    </StyledAnswerBox>
  );
};

export default AnswersBlock;
