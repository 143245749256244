import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { ToolbarAccordion, TextAnnotator, LabelSelector, AnnotationList, Preloader, ProgressBar } from '../components';
import {
  type RootState,
  setAnnotations,
  updateAnnotation,
  setDocumentContent,
  setDocumentSourceType, addNewNotification
} from '../store';
import {
  addEntityToTable,
  addMessageToQueue,
  deleteBlob,
  deleteEntityFromTable,
  downloadBlob,
  getEntityFromTable,
  uploadDataToBlob
} from '../api/queueApi';
import { getAccountInfo, getBlobNameAndSasTokenFromURL, useAzureGarbageCollector, waitFor} from '../utils';
import { msalInstance } from '../index';
import type { Category, Label, AnswersType } from '../types';
import { deleteAnnotation, setQuestionLoadError } from '../store/slices/annotatorSlice';
import { getSasTokenUriForBlob, getSasTokenUriForQueue, getSasTokenUriForTableEntity} from "../api";
import { azureContainerName, azureFunctionQueueCode, azureFunctionBlobCode, azureFunctionTableCode, azureTableName, maxAttempts, timeoutDelay } from "../constants";

const categories = [
  // { labelName: 'Edit', color: '#3357FF', categoryId: 'edit' },
  // { labelName: 'Skip', color: '#FF33A1', categoryId: 'skip' },
  // { labelName: 'Update', color: '#33FFF5', categoryId: 'update' },
  // { labelName: 'Rewrite', color: '#33FF57', categoryId: 'rewrite' },
  // { labelName: 'Question', color: '#FFD133', categoryId: 'question' }, 
  { labelName: 'Added Question', color: '#ECEE63', categoryId: 'userQuestion' }, 
  // { labelName: 'Redundant', color: '#FF5733', categoryId: 'redundant' }, 
];

const questionColor = '#FFD133';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '90vh',
  margin: '0',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

const AnswersBox = styled(Box)(({ theme }) => ({
  top: 0,
  zIndex: 5,
  width: '100%',
  position: 'sticky',
  padding: '5px 0', 
  background: '#FFFFFF'
}));

const AnswersButton = styled(Button)(({theme}) => ({
  color: '#fff',
  width: '100%',
  background: '#02023f',
  '&:hover': {
    background: '#2b2b75',
  }
}));

const TextContainer = styled(Container)(({ theme }) => ({
  width: '65%',
  margin: '10px 0 0',
  overflowY: 'auto',
  height: '100%',
  paddingRight: '10px',
  paddingBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

const SidebarContainer = styled(Container)(({ theme }) => ({
  maxWidth: '35%',
  background: '#eeeeee',
  padding: '0 3px 10px',
  width: '35%',
  overflowY: 'auto',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '35%',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

const StickyToolbarAccordion = styled(ToolbarAccordion)({
  backgroundColor: '#eeeeee',
});

const StickyDocName = styled(Box)({
  position: 'sticky',
  top: 0,
  width: '100%',
  background: '#FFFFFF',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '20px',
  boxSizing: 'border-box',
  zIndex: 5
});

const DocName = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold'
})

type AnswersContent = {
  Id: string;
  AnswersFromEngagementSite: AnswersType[];
  AnswersFromPresalesFolder: AnswersType[];
}

type FoundAnnotation = {
  Context: string;
  StartIndex: number;
  EndIndex: number;
  Question: string;
  OrderId: number;
}

const Annotator: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addRequestData, deleteTableRequest, clearTableStorage, clearBlobStorage } = useAzureGarbageCollector();

  const annotatorContent = useSelector((state: RootState) => state.document.documentContent);
  const annotations = useSelector((state: RootState) => state.annotator.annotations);
  const docType = useSelector((state: RootState) => state.document.documentSourceType);
  const questionLoadError = useSelector((state: RootState)  => state.annotator.questionLoadError);

  const [showCancelRequestsBtn, setShowCancelRequestsBtn] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [loadingAnswers, setLoadingAnswers] = useState([]);
  const [loadAnswersAfterRefresh, setLoadAnswersAfterRefresh] = useState(false);
  const [answerSource, setAnswerSource] = useState<string>('All')
  const mounted = useRef(false);
  const processingRequests = useRef([]);
  const requestCanceled = useRef(false)

  const FileName = searchParams.get('FileName');
  const SiteId = searchParams.get('SiteId');
  const DriveId = searchParams.get('DriveId');
  const ItemId = searchParams.get('ItemId');
  const BlobName = searchParams.get('BlobName');
  const DocType = searchParams.get('DocType');

  useEffect(() => {
    dispatch(setQuestionLoadError(false));
    mounted.current = true;

    if (DocType !== "undefined") {
      dispatch(setDocumentSourceType(DocType));
    }

    if (!annotations.length) {
      const storedAnnotations = sessionStorage.getItem('annotations');
      const storedAnnotatorContent = sessionStorage.getItem('annotatorContent');

      if (storedAnnotations && storedAnnotatorContent) {
        const parsedAnnotations: Label[] = JSON.parse(storedAnnotations);

        dispatch(setDocumentContent(storedAnnotatorContent))
        dispatch(setAnnotations(parsedAnnotations));
        setLoadAnswersAfterRefresh(true);
      } else {
        getAnnotatorContents();
      }
    }

    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (!loadingAnswers.length) {
      setShowCancelRequestsBtn(false)
    }
  }, [loadingAnswers.length])

  useEffect(() => {
    if (loadAnswersAfterRefresh && annotations.length) {
      const questionsWithoutAnswers = annotations.filter(annotation => !annotation.answers);

      setLoadAnswersAfterRefresh(false);
      // questionsWithoutAnswers.length && getAnswers(questionsWithoutAnswers);
    }

  }, [annotations, loadAnswersAfterRefresh]);


  const removeStyles = (htmlString: string) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    
    const styleElements = doc.getElementsByTagName('style');
    for (let i = styleElements.length - 1; i >= 0; i--) {
      styleElements[i].parentNode.removeChild(styleElements[i]);
    }
  
    const elements = doc.getElementsByTagName('*');
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute('style');
    }
  
    return doc.body.innerHTML;
  }

  const scrollToAnnotation = (label: Label) => {
    const element = document.getElementById(label.labelId);

    element.scrollIntoView(false);
  }

  async function getDocumentContent(searchItem: any, maxAttempts: number, containerName: string, sasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

        if (docContent === null) {
          return
        }

        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          break;
        }

        if (docContent && docContent.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(docContent.Response);
          const documentContent = await downloadBlob(containerName, blobName, blobSasToken);
          
          const transformedContent = removeStyles(documentContent);
          const formattedContent = transformedContent.replace(/●[\r\n]+/g, '');

          dispatch(setDocumentContent(formattedContent));
          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  }

  const setAnswers = (answers: AnswersContent[]) => {
    if (!answers.length) return; 

    console.log(answers)

    const answersItem = answers[0];
    const annotationToUpdate = annotations.find(item => item.labelId === answersItem.Id)
    
    const answersWebsiteAnswers = answersItem.AnswersFromEngagementSite?.map(answer => {
      return {
        answer: answer,
        selected: false,
        badge: "AnswersWebsite"
      }
    });
    const sharePointAnswers = answersItem.AnswersFromPresalesFolder.map(answer => {
      return {
        answer: answer,
        selected: false,
        badge: "SharePoint"
      }
    });
  
    const newAnnotation = {
      ...annotationToUpdate,
      answers: [...answersWebsiteAnswers, ...sharePointAnswers]
    } 

    dispatch(updateAnnotation(newAnnotation))
  }

   const getAnswersContent = async (searchItem: any, maxAttempts: number, annotation: any, loadingAnswersIds: string[], sasToken: string) => {
    let attempt = 0;
    let isProcessed = false;

    do {
      const tableItem = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

      if (tableItem === null) {
        setLoadingAnswers([])

        return
      }

      if (tableItem && (tableItem.ResponseStatusCode === 202 || tableItem.Response)) {
        isProcessed = true;
      }

      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    } while (!isProcessed)

    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

        if (docContent === null) {
          setLoadingAnswers([])
          return
        }

        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          if (docContent.ResponseStatusCode === 429) {
            deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
            deleteTableRequest(searchItem.rowKey);

            const annotationForAnswer = annotations.find(item => item.labelId === annotation.Id);

            setTimeout(() => getAnswers([annotationForAnswer]), timeoutDelay)
          }

          if (docContent.ResponseStatusCode === 500) {
            const idx = loadingAnswersIds.findIndex(id => id === annotation.Id);

            loadingAnswersIds.splice(idx, 1);
            processingRequests.current = processingRequests.current.filter(id => id !== searchItem.rowKey);

            const emptyAnswer: AnswersContent[] = [{
              AnswersFromEngagementSite: [],
              AnswersFromPresalesFolder: [], 
              Id: annotation.Id 
            }]
              
            setAnswers(emptyAnswer);
            setLoadingAnswers([...loadingAnswersIds]);
            deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
            deleteTableRequest(searchItem.rowKey);
          }

          break;
        }

        if (docContent && docContent.Response) {
          const answersContent = JSON.parse(docContent.Response);
          const idx = loadingAnswersIds.findIndex(id => id === annotation.Id);

          loadingAnswersIds.splice(idx, 1);
          processingRequests.current = processingRequests.current.filter(id => id !== docContent.rowKey);

          setAnswers(answersContent);
          setLoadingAnswers([...loadingAnswersIds]);
          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      const idx = loadingAnswersIds.findIndex(id => id === annotation.Id);

      loadingAnswersIds.splice(idx, 1);
      processingRequests.current = processingRequests.current.filter(id => id !== searchItem.rowKey);

      const emptyAnswer: AnswersContent[] = [{
        AnswersFromEngagementSite: [],
        AnswersFromPresalesFolder: [],  
        Id: annotation.Id 
      }]
        
      setAnswers(emptyAnswer);
      setLoadingAnswers([...loadingAnswersIds]);
      deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
      deleteTableRequest(searchItem.rowKey);
      console.error('Maximum retries reached, no Response found');
    }
  }

  const setNewAnnotations = useCallback((parsedQuestion: FoundAnnotation[], annotation: Label) => {
    const storageAnnotation: Label[] = JSON.parse(sessionStorage.getItem('annotations'));
    const filteredAnnotations = storageAnnotation.filter(item => annotation.labelId !== item.labelId);

    if (!parsedQuestion.length) {
      const annotationWithoutQuestion = {
        labelId: uuidv4(),
        label: "Added Question",
        start: annotation.start,
        end: annotation.end,
        color: '#ECEE63',
        text: annotation.text,
        question: "No question found",
        order: annotation.order,
      }

      const updatedAnnotations = [...filteredAnnotations, annotationWithoutQuestion];

      dispatch(setAnnotations(updatedAnnotations))
    } else {
      const newAnnotations = parsedQuestion.map((item, idx) => {
        const wordsCount = item.Context.split(' ').length

        return {
          labelId: uuidv4(),
          label: "Added Question",
          start: -1,
          end: -1,
          color: '#ECEE63',
          text: wordsCount > 8 ? item.Context : annotation.text,
          question: item.Question,
          order: annotation.order + idx + 1
        }
      });

      const startIdx = storageAnnotation.findIndex((a) => a.order === annotation.order)
      const startCollection = filteredAnnotations.slice(0, startIdx)
      const endCollection = filteredAnnotations.slice(startIdx)

      const updatedAnnotations = [...startCollection, ...newAnnotations, ...endCollection].map((annotation, idx) => ({
        ...annotation,
        order: idx + 1
      }));

      dispatch(setAnnotations(updatedAnnotations))
    }
  }, [annotations]);

  async function getQuestionFromTextContent(searchItem: any, maxAttempts: number, containerName: string, annotation: Label, sasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          break;
        }

        if (docContent && docContent.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(docContent.Response);
          const question = await downloadBlob(containerName, blobName, blobSasToken);
          const parsedQuestion: FoundAnnotation[] = JSON.parse(question);
                   
          setNewAnnotations(parsedQuestion, annotation);

          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  }

  async function getQuestions(searchItem: any, maxAttempts: number, containerName: string, sasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const questions = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

        if (questions === null) {
          return
        }

        if (questions && questions.ResponseErrorMessage) {
          console.log(questions.ResponseStatusCode, questions.ResponseErrorMessage)
          dispatch(setQuestionLoadError(true));
          setShowCancelRequestsBtn(false);
          break;
        }

        if (questions && questions.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(questions.Response);
          const questionsList = await downloadBlob(containerName, blobName, blobSasToken);

          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);

          // @ts-ignore
          const transformedQuestionList = JSON.parse(questionsList).map(item => {
            return {
              order: item.OrderId,
              question: item.Question,
              start: -1,
              end: -1,
              labelId: `Question-${uuidv4()}`,
              label: 'Question', 
              color: questionColor,
              startIndex: item.StartIndex,
              endIndex: item.EndIndex,
              text: item.Context
            }
          });

          setShowCancelRequestsBtn(false);
          dispatch(setAnnotations(transformedQuestionList));
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found questions');
      dispatch(setQuestionLoadError(true));
      setShowCancelRequestsBtn(false);
    }
  }

  const getAnnotatorContents = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const contentId = uuidv4();
    const questionId = uuidv4();
    const questionQueue = 'get-questions-from-document';
    const contentQueue = 'get-document-content-with-basic-formatting';
    const body = docType === "AzureBlobStorage" ? {
      FileName,
      BlobName,
      DocumentSourceType: docType
    } : {
      FileName,
      SiteId,
      DriveId,
      AccessToken: account.accessToken,
      ItemId,
    }

    const contentTableItem = {
      partitionKey: contentQueue,
      rowKey: contentId,
      Body: JSON.stringify(body),
    }
    const questionTableItem = {
      partitionKey: questionQueue,
      rowKey: questionId,
      Body: JSON.stringify(body),
    }
    const contentTableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, contentId, contentQueue, account.idToken);
    const questionTableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, questionId, questionQueue, account.idToken);

    await addEntityToTable(contentTableItem, contentTableSasToken);
    await addEntityToTable(questionTableItem, questionTableSasToken);
    addRequestData('table', { rowKey: contentId, partitionKey: contentQueue, sasToken: contentTableSasToken });
    addRequestData('table', { rowKey: questionId, partitionKey: questionQueue, sasToken: questionTableSasToken });
    setShowCancelRequestsBtn(true)

    const contentMessage = {
      Id: ItemId,
      TablePartitionKey: contentQueue,
      TableRowKey: contentId,
    }
    const questionMessage = {
      Id: ItemId,
      TablePartitionKey: questionQueue,
      TableRowKey: questionId,
    }

    const contentMessageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, contentQueue, account.idToken);
    const questionMessageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, questionQueue, account.idToken);

    await addMessageToQueue(contentQueue, JSON.stringify(contentMessage), contentMessageSasToken);
    await getDocumentContent(contentTableItem, maxAttempts, azureContainerName, contentTableSasToken);
    await addMessageToQueue(questionQueue, JSON.stringify(questionMessage), questionMessageSasToken);
    await getQuestions(questionTableItem, maxAttempts, azureContainerName, questionTableSasToken)
  }

  const getAnswers = useCallback(async (questions?: Label[]) => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const queueName = 'get-answers-from-questions';
    let questionParam;

    if (questions) {
      questionParam = questions.map(question => ({
        Id: question.labelId,
        Question: question.question,
        NumberOfAnswers: 3,
        PromptFromUser: '',
        SourceType: answerSource,
        AccessToken: account.accessToken
      }));
    } else {
      questionParam = annotations.map(item => {
        return {
          Id: item.labelId,
          Question: item.question,
          NumberOfAnswers: 3,
          PromptFromUser: '',
          SourceType: answerSource,
          AccessToken: account.accessToken
        }
      });
    }

    const loadingAnswersIds = questionParam.map(item => item.Id);
    setLoadingAnswers(loadingAnswersIds.length > 1 ? loadingAnswersIds : [...loadingAnswers, ...loadingAnswersIds]);
    setShowCancelRequestsBtn(true);

    for (const item of questionParam) {
      const uuid = uuidv4();

      if (requestCanceled.current === true) {
        requestCanceled.current = false
        setLoadingAnswers([])

        return
      }

      await waitFor(() => processingRequests.current.length <= 3);

      const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
      uploadDataToBlob(azureContainerName, uuid, [item], blobSasToken);
      addRequestData('blob', { containerName: azureContainerName, blobName: uuid, sasToken: blobSasToken });

      const tableItem = {
        partitionKey: queueName,
        rowKey: uuid,
        Body: uuid,
      }
      const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
      addEntityToTable(tableItem, tableSasToken);
      addRequestData('table', { partitionKey: queueName, rowKey: uuid, sasToken: tableSasToken });

      processingRequests.current.push(tableItem.rowKey);

      const message = {
        Id: item.Id,
        TablePartitionKey: queueName,
        TableRowKey: uuid,
      }
      const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
      addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

      getAnswersContent(tableItem, maxAttempts, item, loadingAnswersIds, tableSasToken);
    }
  }, [annotations, loadingAnswers, answerSource]);

  const getQuestionFromText = async (text: string, annotation: Label) => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName = 'get-questions-from-text';

    const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
    await uploadDataToBlob(azureContainerName, uuid, text, blobSasToken);
    addRequestData('blob', { containerName: azureContainerName, blobName: uuid, sasToken: blobSasToken });

    const contentItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: uuid,
    }
    const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    await addEntityToTable(contentItem, tableSasToken);
    addRequestData('table', { partitionKey: queueName, rowKey: uuid, sasToken: tableSasToken });

    const message = {
      Id: ItemId,
      TablePartitionKey: queueName,
      TableRowKey: uuid,
    }

    const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    await addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

    await getQuestionFromTextContent(contentItem, maxAttempts, azureContainerName, annotation, tableSasToken);
  }

  const updateQueryParams = () => {
    const params = new URLSearchParams();
    params.append('FileName', FileName);
    params.append('SiteId', SiteId);
    params.append('DriveId', DriveId);
    params.append('ItemId', ItemId);

    navigate({
      pathname: '/review-final',
      search: params.toString()
    });
  };

  const handleEdit = useCallback((label: Label) => {
    const newAnnotations = annotations.map(item => {
      if (item.labelId === label.labelId) {
        return label
      } else {
        return item
      }
    })

    dispatch(setAnnotations([...newAnnotations]));
  }, [annotations]);

  const handleUpdateLabels = (updatedLabels: Label[]) => {
    dispatch(setAnnotations(updatedLabels));
  };

  const handleDelete = useCallback((labelId: string) => {
    dispatch(deleteAnnotation(labelId));
  }, [annotations]);

  const getQuestionsOnPress = () => {
    dispatch(setQuestionLoadError(false));
    getAnnotatorContents();
  }

  const everyQuestionHasAnswers = annotations.every(item => item.answers !== undefined);
  const noAnswersFound = annotations.every(item => item.answers?.length === 0);
  const allAnswersSelected = !noAnswersFound && annotations.every(item => item.answers?.every(answer => answer.selected === true));

  const selectAllAnswers = () => {
    if (noAnswersFound) return;

    const newAnnotations = annotations.map(item => {
      const newAnswers = item.answers.map(answer => {
        if (allAnswersSelected) {
          return { ...answer, selected: false }
        } else {
          return { ...answer, selected: true }
        }
      })
      return { ...item, answers: newAnswers }
    })
    dispatch(setAnnotations(newAnnotations));
  }
  const onReviewPage = () => {
    localStorage.setItem('annotations', JSON.stringify(annotations));
    updateQueryParams();
  }

  const setValue = (value: any) => {
    setAnswerSource(value.target.value);
  }

  const onCancelAllRequests = async () => {
    await clearTableStorage()

    setShowCancelRequestsBtn(false)
    requestCanceled.current = true
    dispatch(addNewNotification('Document processing has stopped successfully.'))
  }

  const annotationsWithAnswers = annotations.filter(item => item.answers !== undefined)

  let topPositionForLabel = '0px'; 
  if (annotations.length === 0) {
    topPositionForLabel = '0px';
  } else if (everyQuestionHasAnswers) {
    topPositionForLabel = '118px'
  } else if (showCancelRequestsBtn) {
    topPositionForLabel = '107px'
  } else  {
    topPositionForLabel = '66px'
  }

  return (
    <StyledContainer disableGutters>
      <TextContainer>
        <StickyDocName>
          <DocName>{FileName}</DocName>
        </StickyDocName>
        <Container sx={{ paddingTop: '20px' }}>
          {
            annotatorContent.length ? 
            <TextAnnotator
              text={annotatorContent}
              categories={categories}
              labels={annotations}
              textDisplay="pre-wrap"
              selectedCategory={selectedCategory}
              getQuestionFromText={getQuestionFromText}
              onUpdateLabels={handleUpdateLabels}
              scrollToAnnotation={scrollToAnnotation}
            />
            :
            <Preloader classes={['partial']}/>
           }
        </Container>
      </TextContainer>
      <SidebarContainer disableGutters>
        {
          questionLoadError ? <AnswersButton onClick={getQuestionsOnPress}>Get Questions</AnswersButton> : ''
        }
        <AnswersBox>
          {showCancelRequestsBtn && <AnswersButton sx={{ marginBottom: '5px' }} onClick={onCancelAllRequests}>Stop Document Processing</AnswersButton>}
          {annotations.length ? (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', background: '#02023f', borderRadius: '3px', justifyContent: 'space-around' }}>
                <AnswersButton
                  onClick={() => getAnswers()}
                  sx={{
                    maxWidth: '50%',
                    borderRadius: 0,
                    borderRight: '1px solid #FFF',
                    width: '50%'
                  }}
                >
                  Get Answers
                </AnswersButton>
                <FormControl sx={{ color: '#FFF', maxWidth: '50%', width: '50%', alignItems: 'center' }} variant="filled">
                  <InputLabel
                    id="source-select-label"
                    sx={{
                      color: '#FFF',
                      "&.Mui-focused": {
                        color: '#FFF'
                      },
                    }}
                  >Source</InputLabel>
                  <Select
                    sx={{
                      color: '#FFF',
                      width: '100%',
                      "& .MuiSelect-icon": {
                        color: '#FFF'
                      }
                    }}
                    labelId="source-select-label"
                    id="source-select"
                    value={answerSource}
                    defaultValue="All"
                    label="Age"
                    onChange={setValue}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'SharepointEngagementPresalesFolder'}>Sharepoint</MenuItem>
                    <MenuItem value={'SharepointEngagementAnswersSite'}>Answers</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {
                everyQuestionHasAnswers ? (
                  <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'row', background: '#02023f', borderRadius: '3px', justifyContent: 'space-around' }}>
                    <AnswersButton
                      onClick={onReviewPage}
                      sx={{
                        maxWidth: '50%',
                        borderRadius: 0,
                        borderRight: '1px solid #FFF',
                        width: '50%'
                      }}
                    >
                      Results Overview
                    </AnswersButton>
                    <FormGroup sx={{ color: '#FFF', maxWidth: '50%', width: '50%', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: '#FFF',
                              '&.Mui-checked': {
                                color: '#FFF',
                              },
                            }}
                            onChange={selectAllAnswers}
                            checked={allAnswersSelected}/>
                        }
                        sx={{
                          textTransform: 'uppercase',
                          '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                            fontSize: '0.8rem',
                          }
                        }}
                        label="Select All Answers"
                        labelPlacement='start' />
                    </FormGroup>
                  </Box>
                ) : ''
              }
            </>
          ) : null}
        </AnswersBox>
        <AnswersBox sx={{ top: topPositionForLabel }}>
          {loadingAnswers.length > 0 ?
            <Box>
              <ProgressBar
                progress={annotationsWithAnswers.length / annotations.length * 100}
                text={`${annotationsWithAnswers.length} of ${annotations.length} questions processed`}
              />
            </Box>
            : ''
          }
          <StickyToolbarAccordion label="Add Labels" >
            <LabelSelector categories={categories} onSelect={setSelectedCategory} />
          </StickyToolbarAccordion>
        </AnswersBox>
        <StickyToolbarAccordion label={`Labelled items: ${annotations.length ? annotations.length : ''}`}>
          {
            annotations.length ?
              <AnnotationList loadingAnswers={loadingAnswers} annotations={annotations} onEdit={handleEdit} onDelete={handleDelete} getAnswers={getAnswers} />
            :
              <Preloader />
          }
          { questionLoadError ? <Typography sx={{ textAlign: 'center' }}>An error has occurred try "Get Questions button"</Typography> : ''}
        </StickyToolbarAccordion>
      </SidebarContainer>
    </StyledContainer>
  );
};



export default Annotator;
