import { type ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import {Button, FormControl, InputLabel, Input, Container, Modal, Typography, Select, MenuItem} from "@mui/material";
import { styled } from '@mui/system';
import { DocumentsList } from "../components";
import store, {
  RootState,
  setSearchState,
  clearSearchState,
  addNewNotification,
  clearDocumentContent,
  clearDocumentPreview,
  setDocumentSourceType,
  removeAnnotations,
} from '../store';
import { apiGetPaginatedFilesList, getSasTokenUriForBlob, getSasTokenUriForTableEntity, getSasTokenUriForQueue } from '../api';
import { msalInstance } from "../index";
import { addEntityToTable, addMessageToQueue, uploadFileToBlob } from "../api/queueApi";
import { getAccountInfo, getFilesData, useAzureGarbageCollector } from '../utils';
import { azureFunctionQueueCode, azureFunctionBlobCode, azureFunctionTableCode, azureContainerName, azureTableName } from '../constants';
import type { DocList } from '../types'

const StyledFormControl = styled(FormControl)({
  width: '90%',
  maxWidth: '1200px',
  marginTop: '80px'
});

const StyledContainer = styled(Container)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const StyledButton = styled(Button)<{ marginTop?: string, width?: string }>(({ theme, marginTop, width }) => ({
  marginTop: marginTop || '20px',
  width: width || '40%',
  padding: '10px 0px',
  textTransform: 'none',
  fontSize: 20,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '&:disabled': {
    background: theme.palette.action.disabled,
  },
}));

const StyledLabel = styled(InputLabel)<{  width?: string }>(({ theme, width }) => ({
  width: width || '40%',
  marginTop: '20px',
  padding: '10px 0px',
  borderRadius: '4px',
  textTransform: 'none',
  fontSize: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#2e7d32',
  transform: 'none',
  color: 'white !important',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '&:disabled': {
    background: theme.palette.action.disabled,
  },
}));

const modalContentContainerStyles = {
  minWidth: 800,
  width: 'max-content',
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  gap: '20px'
};

export function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addRequestData } = useAzureGarbageCollector();

  const searchStringFromStore = useSelector((state: RootState) => state.search.searchString);
  const paginatedDocList = useSelector((state: RootState) => state.search.documentsList);
  const currentPage = useSelector((state: RootState) => state.search.page);
  const documentName = useSelector((state: RootState) => state.document.documentName);

  const [url, setUrl] = useState<string>('');
  const [fileDetails, setFileDetails] = useState<DocList>(paginatedDocList);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(15);
  const [pageNumber, setPageNumber] = useState<number>(currentPage);

  useEffect(() => {
    sessionStorage.setItem('table', JSON.stringify([]))
    sessionStorage.setItem('blob', JSON.stringify([]))

    dispatch(setDocumentSourceType('Sharepoint'));
  }, []);

  useEffect(() => {
    setUrl(searchStringFromStore);
  }, [searchStringFromStore]);

  const clearInput = () => {
    setUrl('');
    setFileDetails({ hits: [], total: undefined });
    dispatch(clearSearchState({ hits: [], total: undefined }));
  }

  const getPaginatedFilesList = async(event: unknown, page: number = 1) => {
    setLoading(true);
    if (page) {
      setPageNumber(page)
    } else {
      setPageNumber(1)
    }

    const data = await apiGetPaginatedFilesList(url, page, pageSize);

    setLoading(false);

    if (data?.value) {
      const fileDetailsData = getFilesData(data);

      if (fileDetailsData.hitsContainers[0].total === 0) {
        setFileDetails({ hits: [], total: 0 });
      } else {
        setFileDetails({
          total: fileDetailsData.hitsContainers[0].total,
          hits: [...fileDetailsData.hitsContainers[0].hits]
        })
        dispatch(
          setSearchState(
            {
              searchString: url,
              page,
              documentsList: {
                total: fileDetailsData.hitsContainers[0].total,
                hits: [...fileDetailsData.hitsContainers[0].hits]
              }
            }
          )
        )
      }
    }
  }

  const updateQueryParams = (item: any) => {
    const params = new URLSearchParams();

    params.append('FileName', item.resource?.name || item.fileName);
    params.append('SiteId', item.resource?.parentReference?.siteId);
    params.append('DriveId', item.resource?.parentReference?.driveId);
    params.append('ItemId', item.resource?.id);
    params.append('BlobName', item.blobName);
    params.append('DocType', item.docType);

    navigate({
      pathname: '/preview',
      search: params.toString()
    });
  };

  const getDocContents = async(item: any) => {
    dispatch(clearDocumentPreview())
    dispatch(clearDocumentContent())

    updateQueryParams(item);

    if (documentName !== item.resource.name) {
      dispatch(removeAnnotations())
    }
  }

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const file = e.target.files[0];

    if (file && !(file.type.includes('pdf') || file.type.includes('wordprocessingml.document') || file.type.includes('spreadsheetml.sheet'))) {
      dispatch(addNewNotification('The selected file type is not supported. Please choose a new one, PDF, Excel, or Word type.'))

      return;
    }

    if (file) {
      const blobName = uuidv4();
      const queueName = 'get-document-content-with-formatting';
      const docType = 'AzureBlobStorage';

      const blobSasToken = await getSasTokenUriForBlob(azureContainerName, blobName, azureFunctionBlobCode, account.idToken);
      await uploadFileToBlob(azureContainerName, blobName, file, blobSasToken);
      addRequestData('blob', { blobName, containerName: azureContainerName, sasToken: blobSasToken });

      const tableItem = {
        rowKey: blobName,
        partitionKey: queueName,
        Body: JSON.stringify({
          FileName: file.name,
          BlobName: blobName,
          DocumentSourceType: docType
        }),
      };
      const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, blobName, queueName, account.idToken);
      await addEntityToTable(tableItem, tableSasToken);
      addRequestData('table', { rowKey: blobName, partitionKey: queueName, sasToken: tableSasToken });

      const message = {
        TablePartitionKey: queueName,
        TableRowKey: blobName,
      };
      const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
      await addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

      dispatch(setDocumentSourceType(docType));
      dispatch(clearDocumentPreview());
      dispatch(removeAnnotations());
      dispatch(clearDocumentContent());
      updateQueryParams({
        fileName: file.name,
        blobName: blobName,
        docType
      });
    }
  }

  const disableButtons = !((fileDetails?.hits?.length || url) && !loading);

  return (
    <Provider store={store}>
      <StyledFormControl variant="standard">
        <InputLabel htmlFor="input-with-icon-adornment">
          Document name
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          value={url}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUrl(event.target.value);
          }}
        />
        <StyledContainer disableGutters>
          <StyledButton width="30%" variant="contained" color="primary" disabled={disableButtons} onClick={getPaginatedFilesList}>Search</StyledButton>
          <StyledLabel htmlFor="file" width="30%">Upload File</StyledLabel>
          <input type="file" id="file" style={{ display: 'none' }} onChange={onFileChange} />
          <StyledButton width="30%" variant="contained" color="error" disabled={disableButtons} onClick={clearInput}>Clear</StyledButton>
        </StyledContainer>
      </StyledFormControl>
      {!!fileDetails?.hits &&
        <Container disableGutters>
          <DocumentsList list={fileDetails} loading={loading} getDocContents={getDocContents} updatePage={getPaginatedFilesList} pageNumber={pageNumber} />
        </Container>
      }
      <DisclaimerModal />
    </Provider>
  );
}


function DisclaimerModal() {
  const shouldShow = sessionStorage.getItem('showedDisclaimer')
  const [open, setOpen] = useState<boolean>(!shouldShow ?? true)

  const onClose = () => {
    sessionStorage.setItem('showedDisclaimer', JSON.stringify(true))
    setOpen(false)
  }

  return (
    <Modal open={open}>
      <Container sx={modalContentContainerStyles}>
        <Typography variant="h6" textAlign="center">
          Disclaimer
        </Typography>
        <Typography variant="body1" textAlign="center">
          The Delphi AI application integrates artificial intelligence to assist Engagement Managers in processing Request for Information (RFI) and Request for Proposal (RFP) documents.
        </Typography>
        <Typography variant="body1" textAlign="center">
          The AI component is intended solely to support, not replace, human judgment in the engagement process. It is a tool designed to aid in analyzing RFI/RFP documents, with all final decisions and responsibility for responses remaining with the human engagement team.
        </Typography>
        <Typography variant="body1" textAlign="center">
          Users are reminded of the importance of carefully verifying all inputs and reviewing AI-generated outputs for accuracy, relevance, and compliance with organizational standards.
        </Typography>
        <Typography variant="body1" textAlign="center">
          Users of this application must follow company AI and confidentiality policies, adhering to data security protocols. The Delphi AI assistant is a support tool, not the primary decision-maker; final content responsibility rests with the engagement team, ensuring ethical and legal standards.
        </Typography>

        <Container sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
          <StyledButton width="max-content" variant="contained" color="primary" onClick={onClose}>
            Ok
          </StyledButton>
        </Container>
      </Container>
    </Modal>
  )
}
