import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Box, Tabs, Tab, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/system';
import {
  type RootState,
  clearDocumentContent,
  setDocumentPreview,
  addNewNotification,
  removeAnnotations,
  setLoadFrom,
  setDocumentSourceType
} from '../store';
import { addMessageToQueue, addEntityToTable, getEntityFromTable, downloadBlob, deleteEntityFromTable } from '../api/queueApi';
import { Preloader } from '../components';
import { getSasTokenUriForQueue, getSasTokenUriForTableEntity } from "../api";
import { azureContainerName, azureFunctionQueueCode, azureFunctionTableCode, azureTableName, maxAttempts, timeoutDelay } from "../constants";
import { getAccountInfo, getBlobNameAndSasTokenFromURL, useAzureGarbageCollector } from "../utils";
import { msalInstance } from "../index";

interface PageContent {
  pageHeader: string;
  pageContents: string;
}

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  top: '-40px',
  maxHeight: '85vh',
  overflowY: 'auto',
  position: 'relative',
  marginTop: '40px',
  marginBottom: '-80px',
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: '0 5px',
  border: '1px solid black',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: '#FFF',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  top: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  paddingLeft: '20px',
  marginBottom: '16px',
}))

const DocName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '850px',
  whiteSpace: 'nowrap',
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '&.MuiTabs-root' : {
      margin: '0 -15px 0 -15px',
    }
}))

const StyledButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    marginBottom: '0',
    alignSelf: 'flex-end',
    maxWidth: '300px'
}))

const OuterContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DocumentPreview: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addRequestData, deleteTableRequest } = useAzureGarbageCollector();

  const documentPreview = useSelector((state: RootState) => state.document.documentPreview);
  const docType = useSelector((state: RootState) => state.document.documentSourceType);

  const FileName = searchParams.get('FileName');
  const SiteId = searchParams.get('SiteId');
  const DriveId = searchParams.get('DriveId');
  const ItemId = searchParams.get('ItemId');
  const BlobName = searchParams.get('BlobName');
  const DocType = searchParams.get('DocType');

  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    if (DocType !== "undefined") {
      dispatch(setDocumentSourceType(DocType));
    }

    getPreviewContents();

    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (documentPreview === '') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [documentPreview])

  async function getDocumentContent(searchItem: any, maxAttempts: number, containerName: string, fileName: string, tableSasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, tableSasToken);

        if (docContent && docContent.Response) {
          const { blobName, sasToken } = getBlobNameAndSasTokenFromURL(docContent.Response);
          const documentPreview = await downloadBlob(containerName, blobName, sasToken);

          dispatch(setDocumentPreview({ documentPreview: documentPreview, documentName: fileName }));
          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, tableSasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      dispatch(addNewNotification('The upload process timed out. Please try uploading the file again.'));
      console.error('Maximum retries reached, no Response found');
    }
  }

  const getPreviewContents = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName = 'get-document-content-with-formatting';

    const body = docType === "AzureBlobStorage" ? {
      FileName,
      BlobName,
      DocumentSourceType: docType
    } : {
      FileName,
      SiteId,
      DriveId,
      AccessToken: account.accessToken,
      ItemId,
      DocumentSourceType: docType
    }

    const contentItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: JSON.stringify(body),
    }
    const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    addEntityToTable(contentItem, tableSasToken);
    addRequestData('table', { rowKey: uuid, partitionKey: queueName, sasToken: tableSasToken });

    const message = {
      Id: ItemId,
      TablePartitionKey: queueName,
      TableRowKey: uuid,
    }
    const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

    getDocumentContent(contentItem, maxAttempts, azureContainerName, FileName, tableSasToken);
  }


  const updateQueryParams = () => {
    const params = new URLSearchParams();

    params.append('FileName', FileName);
    params.append('SiteId', SiteId);
    params.append('DriveId', DriveId);
    params.append('ItemId', ItemId);
    params.append('BlobName', BlobName);
    params.append('DocType', docType);

    dispatch(setLoadFrom('server'));
    dispatch(clearDocumentContent());
    dispatch(removeAnnotations())

    navigate({
      pathname: '/annotator',
      search: params.toString()
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {isLoading ?
        <Preloader classes={['fullscreen']}/>
      : 
        <OuterContainer>
          <HeaderContainer>
            <DocName sx={{ fontWeight: 'bold' }}>{FileName}</DocName>
            <StyledButton variant="contained" onClick={updateQueryParams} color="primary">
              Start Document Analysis
            </StyledButton>
          </HeaderContainer>
          
          {Array.isArray(documentPreview) ? (
            <Box>
              <StyledContainer>
                <div dangerouslySetInnerHTML={{ __html: documentPreview[selectedTab].pageContents }} />
              </StyledContainer>
              <StyledTabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons>
                {documentPreview.map((page, index) => (
                  <StyledTab key={index} label={page.pageHeader} />
                ))}
              </StyledTabs>
            </Box>
          ) : (
            <StyledContainer>
              <div dangerouslySetInnerHTML={{ __html: documentPreview }} />
            </StyledContainer>
          )}
      </OuterContainer>
      }
    </>
    
  );
};
