import { type FC, useState } from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import { AnnotationItem } from '../../components';
import { CenteredTypography, CustomModal, StickyBox } from "./components";
import type { Label } from '../../types';
import type { AnnotationListProps } from "./interfaces";

const AnnotationList: FC<AnnotationListProps> = ({ annotations, loadingAnswers, onDelete, onEdit, getAnswers }) => {
  const [showModal, setShowModal] = useState<Label | null>(null);

  const closeModal = () => {
    setShowModal(null);
  };

  const setModalOpen = (item: Label) => {
    setShowModal(item);
  };

  const selectedAnnotation = annotations.find(item => item.labelId === showModal?.labelId)

  return (
    <Box>
      {annotations.length === 0 && (
        <CenteredTypography
          variant="body1"
          mb={1}
        >
          There is no labelled elements for now
        </CenteredTypography>
      )}
      {annotations.map((annotation, index) => {
        const isLoading = loadingAnswers.includes(annotation.labelId);

        return (
          <AnnotationItem
            key={annotation.labelId}
            isLoading={isLoading}
            index={index}
            annotation={annotation}
            openModal={setModalOpen}
            getAnswers={getAnswers}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        );
      })}
      {showModal && (
        <CustomModal open={!!showModal} onClose={closeModal}>
          <StickyBox>
            <IconButton aria-label="edit" onClick={closeModal} sx={{ position: 'relative', right: '-30px' }}>
              <FontAwesomeIcon icon={faX} size="xs" color="rgb(30, 48, 80)" />
            </IconButton>
          </StickyBox>
          <AnnotationItem
            expanded
            isLoading={loadingAnswers.includes(selectedAnnotation.labelId)}
            index={annotations.findIndex(item => item.labelId === selectedAnnotation.labelId)}
            annotation={selectedAnnotation}
            openModal={setModalOpen}
            getAnswers={getAnswers}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </CustomModal>
      )}
    </Box>
  );
};

export default AnnotationList;
